<template>
  <div class="page-box">
    <div style="padding-top:40px;">
      <div v-if="valid==='success'">邮箱验证成功</div>
      <div v-else>邮箱验证中...</div>
    </div>
  </div>
</template>
<script>
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  data () {
    return {
      id: '',
      email: '',
      valid: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => {
        vm.id = to.query.id || ''
        vm.email = to.query.email || ''
        vm.emailValid(vm.id, vm.email)
        vm.emailStatistic(vm.id, vm.email)
      })
    } else {
      document.title = '文献不存在'
      next()
    }
  },
  methods: {
    emailValid (id, email) {
      this.$http.post(`${this.httpRoot}/auth/email/valid`, {id, email}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.valid = 'success'
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    emailStatistic (id, email) {
      this.$http.post(`${this.httpRoot}/auth/email/click`, {id, email, type: 'valid'}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          console.log('eamil click')
        } else {
          console.log(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
